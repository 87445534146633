export default {
    title: 'Dc Transport Equipment Utilization',
    location: '/business/fuo/dc-transport-equipment-utilization',
    tabs: [
        {
            name: 'By Country',
            link: `/business/fuo/dc-transport-equipment-utilization`
        },
        {
            name: 'Trend',
            link: `/business/fuo/dc-transport-equipment-utilization/:market`
        },
        // {
        //     name: 'Top 10',
        //     link: '/customer/customer-order-quality/'
        // }
    ]
}
